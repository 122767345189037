export default {
    install: function (app, options) {
        app.config.globalProperties.$currency = {
            convert: (price) => {
                if(price === null){
                    return null;
                }
                if(!price instanceof Number){
                    price = parseFloat(price);
                }

                return price.toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                });
            }
        }
    }
}
