import axios from 'axios'
import moment from 'moment'

export default {
  install: function (app, options) {
    app.config.globalProperties.$proxyStorage = {
      getData: (subdomain) => {
        return new Promise((resolve, reject) => {
          axios.get("/api/v1/searcher/proxy/" + subdomain).then((response) => {
            resolve(response.data);
          }).catch((e) =>{
            if (e && e.response && e.response.status && e.response.status == 404) {
              console.log("setting proxy state to invalid")
              reject();
            }
          });
        });
      },
      isValid:() => {
        if(app.config.globalProperties.$store.state.proxy.expires == null ){
          console.log("no cache found -> downloading");
          return false;
        }else if(moment(app.config.globalProperties.$store.state.proxy.expires).isBefore(moment())){
          console.log("data expired");
          return false;
        }else{
          console.log("using cache")
          return true;
        }
      }
    }
  }
}
