import { createRouter, createWebHistory } from 'vue-router'
import moment from 'moment'
import login from '../components/login'
import logout from '../components/logout'
import store from '../store/index'
import axios from 'axios'

const routes = [
  {
    path: '/',
    alias: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/dashboard.vue'),
    meta: {
      requiresAuth: true,
      role: 0
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: login,
    meta: {
      noTemplate: true
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: logout
  },
  {
    path: '/apiUnavailable',
    Name: 'Api Unavailable',
    component: () => import('../views/apiUnavailable.vue'),
    meta: {
      offlineUsage: true
    }
  },
  {
    path: '/administration/schools',
    Name: 'Fahrschuladministration',
    component: () => import('../views/schoolAdministration.vue'),
    meta: {
      requiresAuth: true,
      role: 2
    }
  },
  {
    path: '/insufficientPermissions',
    name: 'Insufficient permissions',
    component: () => import('../views/insufficientPermissions.vue'),
    meta: {
      requiresAuth: true,
      role: 0
    }
  },
  {
    path: '/administration/school/:id/edit',
    name: 'edit.school',
    component: () => import('../views/editSchool.vue'),
    meta: {
      requiresAuth: true,
      role:2
    }
  },
  {
    path: '/administration/user/:user/edit',
    name: 'edit.user.admin',
    component: () => import('../views/edit-user.vue'),
    meta: {
      requiresAuth: true,
      role:2
    }
  },
  {
    path: '/school/users/:user/edit',
    name: 'edit.user',
    component: () => import('../views/edit-school-user.vue'),
    meta: {
      requiresAuth: true,
      role:1
    }
  },
  {
    path: '/administration/school/new',
    name: 'new.school',
    component: () => import('../views/newSchool.vue'),
    meta: {
      requiresAuth: true,
      role:2
    }
  },
  {
    path: '/administration/user/new',
    name: 'new.user',
    component: () => import('../views/create-user.vue'),
    meta: {
      requiresAuth: true,
      role:2
    }
  },
  {
    path: '/administration/users',
    name: 'user-administration',
    component: () => import('../views/user-administration.vue'),
    meta: {
      requiresAuth: true,
      role:2
    }
  },
  {
    path: '/school/classes',
    name: 'school-classes',
    component: () => import('../views/class-settings.vue'),
    meta: {
      requiresAuth: true,
      role:1
    }
  },
  {
    path: '/school/classes/new',
    name: 'school-classes-create',
    component: () => import('../views/create-class.vue'),
    meta: {
      requiresAuth: true,
      role:1
    }
  },
  {
    path: '/school/classes/:class/edit',
    name: 'school-classes-edit',
    component: () => import('../views/edit-class.vue'),
    meta: {
      requiresAuth: true,
      role:1
    }
  },
  {
    path: '/school/classes/:class/element/:id',
    name: 'school-classes-edit-element',
    component: () => import('../views/edit-element.vue'),
    meta: {
      requiresAuth: true,
      role:1
    }
  },
  {
    path: '/school/classes/:class/pricing/:id/edit',
    name: 'school-classes-edit-price-list',
    component: () => import('../views/edit-pricelist.vue'),
    meta: {
      requiresAuth: true,
      role:1
    }
  },
  {
    path: '/school/classes/:class/pricing/add',
    name: 'school-classes-add-price-list',
    component: () => import('../views/add-pricelist.vue'),
    meta: {
      requiresAuth: true,
      role:1
    }
  },
  {
    path: '/school/pages',
    name: 'school-pages-get',
    component: () => import('../views/get-pages.vue'),
    meta: {
      requiresAuth: true,
      role:1
    }
  },
  {
    path: '/school/pages/new',
    name: 'school-pages-new',
    component: () => import('../views/create-page.vue'),
    meta: {
      requiresAuth: true,
      role:1
    }
  },
  {
    path: '/school/pages/:id/edit',
    name: 'school-pages-edit',
    component: () => import('../views/edit-page.vue'),
    meta: {
      requiresAuth: true,
      role:1
    }
  },
  {
    path: '/searcher',
    name: 'searcher',
    component: () => import('../views/searcher-school.vue'),
    meta: {
      requiresAuth: true,
      role: 1,
      customPage: true
    }
  },
  {
    path: '/searcher/:group',
    name: 'searcher-driverclass-age',
    component: () => import('../views/searcher-age.vue'),
    meta: {
      requiresAuth: true,
      role: 1,
      customPage: true
    }
  },
  {
    path: '/searcher/:group/:age',
    name: 'searcher-driverclass-result',
    component: () => import('../views/school-searcher-result.vue'),
    meta: {
      requiresAuth: true,
      role: 1,
      customPage: true
    }
  },
  {
    path: '/searcher/:group/:age/:id',
    name: 'searcher-driverclass-class',
    component: () => import('../views/search-class.vue'),
    meta: {
      requiresAuth: true,
      role: 1,
      customPage: true
    }
  },
  {
    path: '/profile/changePassword',
    name: 'change-password',
    component: () => import('../views/change-password'),
    meta: {
      requiresAuth: true,
      role: 0
    }
  },
  {
    path: '/licensing/invoices',
    name: 'licensing-invoices',
    component: () => import('../views/get-invoices'),
    meta: {
      requiresAuth: true,
      role: 2
    }
  },
  {
    path: '/school/users',
    name: 'school-users',
    component: () => import('../views/user-school-administration'),
    meta: {
      requiresAuth: true,
      role: 1
    }
  },
  {
    path: '/school/users/new',
    name: 'school-users-new',
    component: () => import('../views/create-school-user'),
    meta: {
      requiresAuth: true,
      role: 1
    }
  },
  {
    path: '/school/settings',
    name: 'school-settings',
    component: () => import('../views/edit-school-settings'),
    meta: {
      requiresAuth: true,
      role: 1
    }
  },
  {
    path: '/school/price-lists',
    name: 'school-price-lists',
    component: () => import('../views/school-price-lists'),
    meta: {
      requiresAuth: true,
      role: 1
    }
  },
  {
    path: '/authentication/reset',
    name: 'authentication-reset',
    component: () => import('../views/reset-password'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/authentication/requestReset',
    name: 'authentication-requestreset',
    component: () => import('../views/request-password-reset'),
    meta: {
      requiresAuth: false
    }
  }
]

const proxyRoutes = [
  {
    path: '/',
    name: 'proxy',
    component: () => import('../views/proxy'),
    meta: {
      requiresAuth: false,
      customPage: true
    }
  },
  {
    path: '/searcher/:group',
    name: 'proxy-searcher-driverclass-age',
    component: () => import('../views/proxy-searcher-age.vue'),
    meta: {
      requiresAuth: false,
      customPage: true
    }
  },
  {
    path: '/searcher/:group/:age',
    name: 'proxy-searcher-driverclass-result',
    component: () => import('../views/proxy-searcher-result.vue'),
    meta: {
      requiresAuth: false,
      customPage: true
    }
  },
  {
    path: '/searcher/:group/:age/:id',
    name: 'proxy-searcher-driverclass-class',
    component: () => import('../views/proxy-search-class.vue'),
    meta: {
      requiresAuth: false,
      customPage: true
    }
  }
]

var routeSet;
var url = window.location.host.split(':')[0].split('.');
var subdomain = url[0].toLowerCase();

if(subdomain == "staging" || subdomain == "production" || subdomain == "localhost" || subdomain == "192" || url.length == 2){
  routeSet = routes;
}else{
  store.commit("setSubdomain", subdomain);
  routeSet = proxyRoutes;
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routeSet
})

router.beforeEach(async (to, from, next) => {
  // check if api error page
  if (to.matched.some(record => record.meta.offlineUsage)) {
    return next()
  }
  // check if api ping is enough
  if (!store.getters.validPing) {
    console.log('ping invalid trying')
    try {
      var result = await axios.get('/ping')
    } catch (e) {
      console.log('api down')
      return next({
        path: '/apiUnavailable'
      })
    }
    if (!result.data || result.data !== 'pong') {
      return next({
        path: '/apiUnavailable'
      })
    } else {
      console.log('save ping')
      store.commit('setPing')
    }
  } else {
    console.log('ping valid')
  }
  // check for needed authentication
  console.log('check for needed auth')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // check if token is valid
    var tokenValid = moment(store.state.authentication.validUntil).isAfter(moment())
    if (store.state.authentication.authenticated && tokenValid) {
      console.log('token valid')
      //check for permissions
      if(to.meta.role <= store.state.user.role){
        //set axios settings
        axios.defaults.headers.common = { Authorization: `Bearer ${store.state.authentication.token}` }
        next();
      }else{
        next({
          path: '/insufficientPermissions'
        })
      }
    } else {
      store.commit('revokeToken');
      console.log('token invalid redirect to login')
      // token invalid
      next({
        path: '/login',
        query: { redirect: to.fullPath, tokenExpired: true }
      })
    }
  } else {
    next()
  }
})

export default router
