<template>
  <div></div>
</template>

<script>
import router from '../router/index'
import axios from 'axios'

export default {
  mounted () {
    axios.get("/api/v1/authentication/logout").then((response) => {
      this.logout();
    }).catch((e) => {
      this.logout();
    })
  },
  methods:{
    logout(){
      this.$store.commit('revokeToken');
      router.push('Login');
    }
  }
}
</script>
