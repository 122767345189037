import createPersistedState from 'vuex-persistedstate'
import axios from 'axios'
import moment from 'moment'

import { createStore } from 'vuex'

export default new createStore({
  state: {
    authentication: {
      token: null,
      authenticated: false,
      issuedAt: null,
      validUntil: null
    },
    user: {
      firstname: null,
      name: null,
      mail: null,
      lastAuthenticated: null,
      school: null,
      role: null
    },
    ping: {
      successful: false,
      time: null
    },
    schoolsettings: {},
    licensing: {
      features: null
    },
    proxy: {
      expires: null,
      features: null,
      settings: null,
      school: null,
      subdomain: null
    },
    searcher: {
      age: null,
      gap: null
    }
  },
  mutations: {
    setToken (state, { token, issuedAt, validUntil }) {
      state.authentication.token = token
      state.authentication.authenticated = true
      state.authentication.issuedAt = issuedAt
      state.authentication.validUntil = validUntil

      axios.defaults.headers.common = { Authorization: `Bearer ${token}` }
    },
    revokeToken (state) {
      state.authentication.token = null
      state.authentication.authenticated = false
      state.authentication.issuedAt = null
      state.authentication.validUntil = null
    },
    setUserInformation (state, userData) {
      state.user.firstname = userData.firstname
      state.user.name = userData.name
      state.user.mail = userData.mail
      state.user.role = userData.role
      state.user.school = userData.school
      if (userData.lastAuthenticated) state.user.lastAuthenticated = userData.lastAuthenticated
    },
    setPing (state) {
      state.ping.successful = true
      state.ping.time = moment().add('15', 'm').toDate()
    },
    setSchoolSettings (state, settings) {
      state.schoolsettings = {};
      settings.forEach((setting) => {
        state.schoolsettings[setting.key] = setting.value;
      })
    },
    setLicensedFeatures (state, features) {
      state.licensing.features = features;
    },
    setProxySettings (state, data) {
      state.proxy.expires = moment().add('60', 'm').toDate();
      state.proxy.features = data.features;
      state.proxy.settings = data.settings;

      state.schoolsettings = {};
      data.settings.forEach((setting) => {
        state.schoolsettings[setting.key] = setting.value;
      })

      state.proxy.school = data.school;
    },
    setSubdomain (state, subdomain) {
      state.proxy.subdomain = subdomain;
    },
    setAge (state, age) {
      if(moment.isMoment(age)){
        state.searcher.age = moment().diff(age, 'years', true);
        var xy = state.searcher.age;
        if( xy - Math.floor(state.searcher.age) >= 1.0){
          state.searcher.gap = true;
        }else{
          state.searcher.gap = false;
        }
      }
    },
    resetAge (state) {
      state.searcher.age = null;
      state.searcher.gap = null;
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    schoolSettings: (state) => (key) => {
      return state.schoolsettings[key];
    },
    validPing: state => {
      return state.ping.successful && moment(state.ping.time).isAfter(moment())
    },
    getAge: state => {
      return state.searcher.age;
    },
    getAgeGap: state => {
      return state.searcher.gap;
    }
  },
  plugins: [createPersistedState()]
})
