<template>
  <div>
    <component :is="'style'">
      :root {
      --navbar-color: {{ $store.getters.schoolSettings('navbarColor')}};
      --navbar-text-color: {{ $store.getters.schoolSettings('navbarTextColor')}};
      --page-background-color: {{$store.getters.schoolSettings('pageBackgroundColor')}};
      --page-content-color: {{ $store.getters.schoolSettings('pageTextColor')}};
      --heading-color-h1: {{$store.getters.schoolSettings('headingColors.h1')}};
      --heading-color-h2: {{$store.getters.schoolSettings('headingColors.h2')}};
      --heading-color-h3: {{$store.getters.schoolSettings('headingColors.h3')}};
      --heading-color-h4: {{$store.getters.schoolSettings('headingColors.h4')}};
      --heading-color-h5: {{$store.getters.schoolSettings('headingColors.h5')}};
      --button-color: {{$store.getters.schoolSettings('buttonColor')}};
      --button-text-color: {{$store.getters.schoolSettings('buttonTextColor')}};
      }
    </component>
    <div id="nav" v-if="$store.state.authentication.authenticated">
      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
          <router-link to="/">
            <template v-if="$store.getters.schoolSettings('logoUri') == ''">
              Führerschein Tool
            </template>
            <template v-else>
              <img :src="$store.getters.schoolSettings('logoUri')" height="24">
            </template>
          </router-link>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link to="/" class="nav-link active" aria-current="page">Dashboard</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/searcher" class="nav-link">Klassensucher</router-link>
              </li>
              <li class="nav-item dropdown" v-if="$store.state.user.role >= 1 && $store.state.user.school != null">
                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Fahrschulverwaltung
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><router-link to="/school/classes" class="dropdown-item">Klasseneinstellungen</router-link></li>
                  <li><router-link to="/school/pages" class="dropdown-item">Individuelle Seiten</router-link></li>
                  <li><router-link to="/school/users" class="dropdown-item">Benutzerverwaltung</router-link></li>
                  <li><router-link to="/school/settings" class="dropdown-item">Einstellungen</router-link></li>
                  <li><router-link to="/school/price-lists" class="dropdown-item">Preislisten bearbeiten</router-link></li>
                </ul>
              </li>
              <li class="nav-item dropdown" v-if="$store.state.user.role >= 2">
                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Mandantenverwaltung
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><router-link to="/administration/schools" class="dropdown-item">Fahrschulverwaltung</router-link></li>
                  <li><router-link to="/administration/users" class="dropdown-item">Benutzerverwaltung</router-link></li>
                  <li><router-link to="/licensing/invoices" class="dropdown-item">Abrechnung</router-link></li>
                </ul>
              </li>
            </ul>
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {{$store.state.user.firstname}} {{$store.state.user.name}}
                </a>
                <ul class="dropdown-menu dropdown-menu-lg-end" aria-labelledby="navbarDropdown">
                  <li><router-link to="/profile/changePassword" class="dropdown-item">Passwort ändern</router-link></li>
                  <li><router-link to="/logout" class="dropdown-item">Logout</router-link></li>
                  <li><hr class="dropdown-divider"></li>
                  <li><a class="dropdown-item disabled">Version: {{commitHash}}</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
      <template v-if="$route.meta.noTemplate">
        <div class="reset">
          <router-view/>
        </div>
      </template>
      <template v-else>
          <div id="content" :class="{frontEndStyling: $route.meta.customPage}">
            <div>
              <router-view/>
            </div>
        </div>
      </template>
  </div>
</template>

<script>
export default {
  computed: {
    commitHash(){
      return process.env.VUE_APP_COMMIT_HASH;
    }
  },
  created () {
    document.title = "DFM";
  }
}
</script>

<style lang="scss">
html,body, #app, #app > div, #content, .reset {
  height:100%;
  overflow:hidden;
}

#app {
  padding:0;
}

#content {
  padding:0 0 56px 0 !important;

  > div {
    overflow-y: auto !important;
    overflow-x: hidden;

    min-height: 100%;
    padding:50px 10% 0 10%;
    @media screen and (max-width: 1024px) {
      padding:10px 2% 0 2%;
    }
    height: 100%;
    width: 100%;

    > * {
      height: 100%;
    }
  }

  &.reset {
    margin:0;
    padding:0;
  }
}

.frontEndStyling {
  font-size: 25px;
  line-height:40px;

  @media screen and (max-width: 1024px) {
    font-size: 10px;
    line-height:20px;
  }

  word-break: break-word;

  background-color: var(--page-background-color);
  color: var(--page-content-color) !important;

  .btn {
    &, &:hover, &:focus {
      background-color: var(--button-color);
      border-color: var(--button-color);
      color: var(--button-text-color);
      box-shadow:none;
    }
  }

  h1, .tiny-mce-wrapper h1 {
    padding:15px 0 40px 0;
    font-size: 50px;
    color: var(--heading-color-h1) !important;

    @media screen and (max-width: 1024px) {
      padding:5px 0 20px 0;
      font-size: 20px;
    }
  }

  h2, .tiny-mce-wrapper h2 {
    padding:10px 0 30px 0;
    font-size: 40px;
    color: var(--heading-color-h2) !important;

    @media screen and (max-width: 1024px) {
      padding:5px 0 10px 0;
      font-size: 10px;
    }
  }

  h3, .tiny-mce-wrapper h3 {
    color: var(--heading-color-h3) !important;
  }

  h4, .tiny-mce-wrapper h4 {
    color: var(--heading-color-h4) !important;
  }

  h5, .tiny-mce-wrapper h5 {
    color: var(--heading-color-h5) !important;
  }

  .table {
    color: var(--page-content-color) !important;
  }
}

@media screen and (max-width: 1024px) {
  #content > .frame {
    padding: 50px 5% 0 5% !important;
  }
  #nav > {
    padding: 0 5% !important;
  }
}

nav {
  height: 56px;
  padding:0 10%;
  background-color: var(--navbar-color);
  color: var(--navbar-text-color);
  -webkit-box-shadow: 0px 0px 50px -20px #000000;
  box-shadow: 0px 0px 50px -20px #000000;

  .container-fluid > a:first-child {
    margin-right:10px;
    text-decoration: none;
    font-weight: bold;
  }

  ul.dropdown-menu {
    background-color: var(--navbar-color);
  }

  a {
    color: var(--navbar-text-color) !important;
  }

  button.navbar-toggler {
    background-color:white;
  }
}
</style>
