import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_API_BACKEND

import proxyStorage from "./plugins/proxy-storage";
import currency from "./plugins/currency";

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

createApp(App)
  .use(store)
  .use(router)
  .use(proxyStorage)
  .use(currency)
  .mount('#app')
