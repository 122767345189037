<template>

      <div id="main" class="frameReset">
        <div id="loginForm">
          <h1>Digitales Fahrschulmanagement</h1>
          <hr>
          <h5>Anmelden</h5>
          <div v-if="error">
            <div class="alert alert-danger" role="alert">
              {{error}}
            </div>
            <hr>
          </div>
          <div v-else-if="'passwordChanged' in $route.query">Passwort geändert</div>
          <form @submit.prevent="onSubmit">
            <div class="form-floating mb-3">
              <input type="email" class="form-control" id="mail" v-model="mail">
              <label for="mail">E-Mail</label>
            </div>
            <div class="form-floating mb-3">
              <input type="password" class="form-control" id="password" v-model="password">
              <label for="password">Passwort</label>
            </div>

            <div class="row">
              <div class="col-md">
                <button type="submit" class="btn btn-primary">Anmelden</button>
              </div>
              <div class="col-md col-right">
                <input type="button" class="btn btn-secondary" @click="$router.push('/authentication/requestReset')" value="Passwort zurücksetzen">
              </div>
            </div>
            <hr>
            <footer>Version: {{commitHash}}</footer>
          </form>
        </div>
      </div>
</template>

<style type="sass" scoped>
footer {
  color: grey;
  font-size: 10px;
}

@media only screen and (max-width: 480px) {
  #main {
    background-image: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    padding-top: 0 !important;
  }

  #loginForm {
    background-image: none !important;
    -webkit-box-shadow: none !important;
    background-color:red;
    width:100% !important;
    margin: 0 !important;
    padding:20px 10px !important;
  }

  .col-md{
    margin-bottom:5px;
  }
}

#main {
  -webkit-box-shadow: inset 0px 0px 50px -30px #000000;
  box-shadow: inset 0px 0px 50px -30px #000000;
  padding-top: 10%;
  width:100%;
  height:100%;
  background-image: url('../assets/images/login/background.jpg');
}
#loginForm {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding:20px 30px;
  background-color:white;
  width:500px;
  height:auto;
  margin:0 auto;
}
.col-right {
  margin-left: auto;
  margin-right: 0;
}
</style>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      mail: '',
      password: '',
      error: null
    }
  },
  computed: {
    commitHash(){
      return process.env.VUE_APP_COMMIT_HASH;
    }
  },
  methods: {
    onSubmit () {
      if (this.password.length > 0) {
        axios.post('/api/v1/authentication', {
          mail: this.mail,
          password: this.password
        })
          .then(async (response) => {
            // save token
            this.$store.commit('setToken', {
              token: response.data.token,
              issuedAt: new Date(response.data.issuedAt),
              validUntil: new Date(response.data.validUntil)
            })
            // get user data
            var userData = await axios.get('/api/v1/users/')
            this.$store.commit('setUserInformation', userData.data.user)
            this.$store.commit('setLicensedFeatures', userData.data.licenses);
            // get style data
            if(userData.data.user.school){
              axios.get("/api/v1/settings/"+userData.data.user.school).then(async (response) => {
                this.$store.commit('setSchoolSettings', response.data);
                await this.$router.push('dashboard')
              }).catch((err) => {
                console.log(err);
                alert("error while fetching school settings");
              })
            }else{
              await this.$router.push('dashboard')
            }
          })
          .catch((error) => {
            console.log(error)
            this.error = error.response.data
          })
      }
    }
  }
}
</script>
